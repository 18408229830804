

import '../styles/Page.css'
import Home from '../Components/Home';
import CardsPage from '../Components/CardsPage';
import Global from '../Components/Global';
import Value from '../Components/Value';
import Company from '../Components/Company';
import Team from '../Components/Team';
import RealIntern from '../Components/Realintern';
import Match from '../Components/Match';
import Form from '../Components/Form';
import OurMission from '../Components/OurMission';
import Footer from '../Components/Footer';

export default function Page() {
    return (
        <>
            <Home />
            <div className="page-content">
                <CardsPage />
                <OurMission />
                <Global />
                <Value />
                <Company />
                <Team />
                <RealIntern />
                <Match />
                <Form />
                <Footer />
            </div>

        </>
    );
}
