import React from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate'i import ediyoruz
import footerLogo from '../images/logo.svg';
import '../styles/Footer.css'

export default function Footer() {
  const navigate = useNavigate(); // useNavigate fonksiyonunu tanımlıyoruz

  const handleNavigation = (path, targetId) => {
    navigate(path);
    setTimeout(() => {
      if (targetId) {
        document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' });
      }
    }, 500); // Sayfa yüklendikten sonra kaydırma işlemini başlatıyoruz
  };

  return (
    <footer className="footer-custom">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center py-5">
          <div className="col-md-4 col-12">
            <a href="/" className="footer-logo-link">
              <img src={footerLogo} alt="RealWorks Logo" className="footer-logo" />
            </a>
          </div>
          <div className="col-md-4 col-6">
            <ul className="footer-nav">
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'who')}>
                <div className="footer-link">Who We Are</div>
              </li>
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'our')}>
                <div className="footer-link">our mission</div>
              </li>
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'global')}>
                <div className="footer-link">Benefits</div>
              </li>
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'value')}>
                <div className="footer-link">value</div>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-6">
            <ul className="footer-nav">
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'fit')}>
                <div className="footer-link">Teams</div>
              </li>
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'intern')}>
                <div className="footer-link">RealIntern</div>
              </li>
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'match-menu')}>
                <div className="footer-link">How We Hire?</div>
              </li>
              <li className="nav-item-custom" onClick={() => handleNavigation('/', 'form-menu')}>
                <div className="footer-link">Apply Now</div>
              </li>
              <li className="nav-item-custom" onClick={() => handleNavigation('/faq')}>
                <div className="footer-link">FAQ</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="footer-bottom-text">
              © 2024 RealMinds. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
