import React from 'react'
import '../styles/Realintern.css';


export default function RealIntern() {
    return (
        <div id='intern'>
            <div className='intern'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='intern-header'>realintern program</h1>
                        </div>
                    </div>
                    <div className="row pb-5">
                        <div className="col-12">
                            <h3 className='intern-title'>Ready to shake things up with your next-gen spirit?</h3>
                            <span className='intern-subtitle'>Share your passions, your goals, and what makes you unique, we’ll teach you the rest.</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className='intern-text'>Our intern program is all about real experiences. Dive into exciting projects, work with passionate teams,
                                and help create something awesome. You’ll make a real impact on our business and beyond. Every single one
                                of our RealInterns are our best team players, and we can’t wait to see what you bring to the table.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <a href="https://realintern.realworksglobal.com">
                                <button className='intern-explore'>Explore Now</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    );
}