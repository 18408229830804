import React from 'react';
import '../styles/Value.css';

export default function Value() {
    return (
        <div className="work-page" id='value'>
            <div className="container">
                <div className="image-value-container">
                    <h1 className='value-title'>We value the work <br />you do over where <br />you do it.</h1>
                    <p className='value-text'>
                        Whether you choose to work from the comfort of your home (made cozy with our home kit), your favorite park,
                        or from one of our global offices, our focus is on the power of your contributions.
                        Here, you’ll find the flexibility to work where you feel most inspired and supported, ensuring you can thrive no matter the location.
                    </p>
                </div>
            </div>
        </div >
    );
}
