import React from 'react';
import Card from './Card';
import image1 from '../images/Cardfoto1.png';
import image2 from '../images/Cardfoto2.png'
import image3 from '../images/Cardfoto3.png'

export default function CardsPage() {
  return (
    <div className="cards-wrapper">
      <Card
        id="card1"
        title="Who we are?"
        text={
          <>
            As a subsidiary of the innovative powerhouse RealWorks Global, we are more than a creative marketing firm—
            <span>we’re a movement</span>.
          </>
        }
        imageSrc={image1}
        isFirstCard
      />

      <Card
        id="card2"
        title="What we do?"
        text="We’ve been redefining the rules, merging new technologies with creativity to not just capture attention but to ignite it. Hence, ensuring our clients’ visions are realized, their brand presence enhanced and their impact lasting."
        imageSrc={image2}
        isMidCard
      />
      <Card
        id="card3"
        title="What it means to belong?"
        text="Being a part of RealMinds means hanging out with friends every day, having a say in high-impact projects, and being your authentic self. You’ll work in an unfiltered environment, creating the most innovative and future-proof work. Here, your creativity and voice matter"
        imageSrc={image3}
        isLastCard
      />
    </div>
  );
}
