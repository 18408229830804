import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';  // Ekran boyutu kontrolü için
import '../styles/Team.css';
import 'swiper/css';
import 'swiper/css/pagination';
import image1 from '../images/fit1.png';
import image2 from '../images/fit2.png';
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function Team() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });  // Mobil kontrolü

    useEffect(() => {
        AOS.init({
            duration: 800, // Animasyon süresi (ms)
        });
    }, []);


    const departmentsMobile = [
        { title: 'Strategy', image: null, color: '#fff', backColor: '#0b161a', heightClass: 'height-368' },
        { title: null, image: image1, backColor: null, color: null, heightClass: 'image-184' },
        { title: 'Production', image: null, backColor: '#0b161a', color: '#fff', heightClass: 'height-184' },
        { title: null, image: image2, backColor: null, color: null, heightClass: 'image-368' },
        { title: 'Graphic Design / Motion', image: null, backColor: '#FC8056', color: '#fff', heightClass: 'height-243' },
        { title: 'Social Media & Content Creation', image: null, backColor: '#FFF', heightClass: 'height-300' },
        { title: 'Project Management', image: null, backColor: '#FC8056', color: '#fff', heightClass: 'height-243' },
        { title: 'Account Management', image: null, backColor: '#FFF', heightClass: 'height-300' },
    ];

    const departments = [
        { title: 'Strategy', image: null, color: '#fff', backColor: '#0b161a', heightClass: 'height-368' },
        { title: null, image: image1, backColor: null, color: null, heightClass: 'image-184' },
        { title: 'Graphic Design / Motion', image: null, backColor: '#FC8056', color: '#fff', heightClass: 'height-243' },
        { title: 'Social Media & Content Creation', image: null, backColor: '#FFF', heightClass: 'height-300' },
        { title: 'Production', image: null, backColor: '#0b161a', color: '#fff', heightClass: 'height-184' },
        { title: null, image: image2, backColor: null, color: null, heightClass: 'image-368' },
        { title: 'Project Management', image: null, backColor: '#FC8056', color: '#fff', heightClass: 'height-243' },
        { title: 'Account Management', image: null, backColor: '#FFF', heightClass: 'height-300' },
    ];

    const activeDepartments = isMobile ? departmentsMobile : departments;


    return (
        <div id='fit'>
            <div className="fit-top-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='fit-title'>Our Teams</h1>
                            <p className='fit-text'>Meet the minds behind the magic and discover
                                the creative forces driving us forward.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="know-page">
                <div className="container">
                    <div className="cards-section">
                        <div data-aos="fade-up" className={`fit-masonry ${isMobile ? 'mobile' : ''}`}>
                            {activeDepartments.map((dept, i) => (
                                <div
                                    key={i}
                                    className={`fit-masonry-item ${dept.heightClass}`}
                                    style={{
                                        color: dept.color,
                                        backgroundColor: dept.backColor
                                    }}
                                >
                                    {dept.image ? (
                                        <img src={dept.image} alt={dept.title} className="fit-masonry-image" />
                                    ) : (
                                        <div className="fit-masonry-text">
                                            <span>{dept.title}</span>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}
