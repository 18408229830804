import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Page from './Pages/Page';
import Faq from './Components/Faq';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Page />}/>
        <Route path="/faq" element={<Faq />}/>

      </Routes>
    </Router>
  );
}

export default App;
