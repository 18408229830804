import React from 'react';
import '../styles/Home.css'
import Header from './Header';
import image from '../images/home-bg.png'

export default function Home() {
    const handleClick = () => {
        window.location.href = '#form';
    }
    return (
        <>
            <Header />
            <div className="home-wrapper">
                <div className="container">
                    <div className="home-container">
                        <div className="image-container">
                            <div className="text-content">
                                <h1 className="title">Join the <span className='mind'>minds</span><br />behind the <span className='mind'>real</span> work</h1>
                            </div>
                            <img src={image} alt="Real People" className="home-image" />
                            <button className="apply-now-btn" onClick={handleClick}>Apply Now</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="empty"></div>
        </>
    );
}
