import React, { useRef } from 'react';
import '../styles/OurMission.css';
import slidearrowleft from '../images/Sliderarrowleft.svg';
import slidearrowright from '../images/Sliderarrowright.svg';
import slider1 from '../images/kir.png';
import slider2 from '../images/slider2.png';
import slider3 from '../images/slider3.png';
import slider4 from '../images/slider4.png';
import slider5 from '../images/slider5.png';

import keepitreal from '../images/Slider2solfoto.svg';
import valuebased from '../images/slider2ikincifoto.svg';
import begreat from '../images/begreat.svg';
import stayhungry from '../images/stayhungry.svg';
import originality from '../images/Originality.svg';

const OurMission = () => {
  const activeSlideIndex = useRef(0);

  const goToSlide = (index) => {
    activeSlideIndex.current = index;
    document.querySelectorAll('.slide-content').forEach((slide, i) => {
      slide.style.display = i === index ? 'block' : 'none';
    });
  };

  const goToNextSlide = () => {
    const nextIndex = (activeSlideIndex.current + 1) % 5;
    goToSlide(nextIndex);
  };

  const goToPreviousSlide = () => {
    const prevIndex = (activeSlideIndex.current - 1 + 5) % 5;
    goToSlide(prevIndex);
  };

  return (
    <div className="mission">
      <div className="our-mission-container" id="our">
      <div className="our-mission-content">
        <h2>Our mission?</h2>
        <p>
          To craft experiences that do more than resonate <span className='our-mission-content-span'>—they disrupt</span>. <br /> We’re in the business of making legacies, not just campaigns.
          We dare to dream big, act boldly, and challenge every limit. For us, every challenge is a playground, every project a chance
          to push further, dig deeper, and rise higher.
        </p>
      </div>
      <div className="slider-wrapper">
        <button className="prev-slide" onClick={goToPreviousSlide}>
          <img src={slidearrowleft} alt="Previous Slide" />
        </button>
        <div className="slider-container">
          <div className="slide-container">
            {/* Slide 1 */}
            <div className="slide-content" style={{ display: activeSlideIndex.current === 0 ? 'block' : 'none' }}>
              <div className="image-row">
                <img src={slider1} alt="Slide 1" className="slider-image" />
                <img src={originality} alt="Originality" className="slider-fotos" onClick={() => goToSlide(1)} />
                <img src={valuebased} alt="Value-Based Agility" className="slider-fotos" onClick={() => goToSlide(2)} />
                <img src={begreat} alt="Be Great Together" className="slider-fotos" onClick={() => goToSlide(3)} />
                <img src={stayhungry} alt="Stay Hungry" className="slider-fotos" onClick={() => goToSlide(4)} />
              </div>
            </div>

            {/* Slide 2 */}
            <div className="slide-content" style={{ display: activeSlideIndex.current === 1 ? 'block' : 'none' }}>
              <div className="image-row">
                <img src={keepitreal} alt="Keep It Real" className="slider-fotos" onClick={() => goToSlide(0)} />
                <img src={slider2} alt="Slide 2" className="slider-image" />
                <img src={valuebased} alt="Value-Based Agility" className="slider-fotos" onClick={() => goToSlide(2)} />
                <img src={begreat} alt="Be Great Together" className="slider-fotos" onClick={() => goToSlide(3)} />
                <img src={stayhungry} alt="Stay Hungry" className="slider-fotos" onClick={() => goToSlide(4)} />
              </div>
            </div>

            {/* Slide 3 */}
            <div className="slide-content" style={{ display: activeSlideIndex.current === 2 ? 'block' : 'none' }}>
              <div className="image-row">
                <img src={keepitreal} alt="Keep It Real" className="slider-fotos" onClick={() => goToSlide(0)} />
                <img src={originality} alt="Originality" className="slider-fotos" onClick={() => goToSlide(1)} />
                <img src={slider3} alt="Slide 3" className="slider-image" />
                <img src={begreat} alt="Be Great Together" className="slider-fotos" onClick={() => goToSlide(3)} />
                <img src={stayhungry} alt="Stay Hungry" className="slider-fotos" onClick={() => goToSlide(4)} />
              </div>
            </div>

            {/* Slide 4 */}
            <div className="slide-content" style={{ display: activeSlideIndex.current === 3 ? 'block' : 'none' }}>
              <div className="image-row">
                <img src={keepitreal} alt="Keep It Real" className="slider-fotos" onClick={() => goToSlide(0)} />
                <img src={originality} alt="Originality" className="slider-fotos" onClick={() => goToSlide(1)} />
                <img src={valuebased} alt="Value-Based Agility" className="slider-fotos" onClick={() => goToSlide(2)} />
                <img src={slider4} alt="Slide 4" className="slider-image" />
                <img src={stayhungry} alt="Stay Hungry" className="slider-fotos" onClick={() => goToSlide(4)} />
              </div>
            </div>

            {/* Slide 5 */}
            <div className="slide-content" style={{ display: activeSlideIndex.current === 4 ? 'block' : 'none' }}>
              <div className="image-row">
                <img src={keepitreal} alt="Keep It Real" className="slider-fotos" onClick={() => goToSlide(0)} />
                <img src={originality} alt="Originality" className="slider-fotos" onClick={() => goToSlide(1)} />
                <img src={valuebased} alt="Value-Based Agility" className="slider-fotos" onClick={() => goToSlide(2)} />
                <img src={begreat} alt="Be Great Together" className="slider-fotos" onClick={() => goToSlide(3)} />
                <img src={slider5} alt="Slide 5" className="slider-image" />
              </div>
            </div>
          </div>
        </div>
        <button className="next-slide" onClick={goToNextSlide}>
          <img src={slidearrowright} alt="Next Slide" />
        </button>
      </div>
    </div>
    </div>
  );
};

export default OurMission;
