import React from 'react';
import Header from './Header';

import '../styles/Faq.css';

export default function Faq() {
    const accordionData = [
        { title: "What’s it really like to be a #RealTeam member?", content: <>Glad you asked! You can visit the life pages of all three companies from <a className='faq-link' href="/">here</a> to explore more.</> },
        { title: "Are the companies of RW Global independent entities?", content: "Yes, all of our companies are independent entities, each with its own identity and operations. Nevertheless, they collaborate under the same umbrella, sharing resources, knowledge, and a unified culture. This setup allows each company to maintain its unique focus while benefiting from the collective strength and support of the broader RW Global community." },
        { title: "How long does your hiring process take?", content: "As RealTeam, we're all about cutting through obstacles and getting things done efficiently. When it comes to hiring, we make sure things are done withina month - no dragging things out here." },
        { title: "Will I hear back if my application doesn’t move forward?", content: "Yes, we’ll let you know either way. We believe in keeping the communication open and transparent." },
        { title: "Do I use the same application panel for all three companies?", content: "Each of our companies has its own dedicated career page, reflecting their unique work areas and teams. Wherever your passion leads you, you can find the career page for the company that fits you best through the links below. And if you're still in school and looking for an internship, you can apply to our Reallntern program." },
        { title: "Is your hiring process inclusive?", content: "100%. We’re committed to being an inclusive employer, valuing diversity, and ensuring our hiring process is fair and welcoming to everyone." },
        { title: "Is an orientation program waiting for me after I start working?", content: "From day one, you’ll dive into a quick-start online program that gives you all the essentials in bite-sized pieces. And for everything else, every RealTeam member will be there to happily answer any questions you have along the way." },
       
    ];

    return (
        <div className='faq-section'>
            <Header />
            <div>
                <div className="container">
                    <div className="row faq-top-section">
                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                            <h1 className='faq-header'>Recruitment Process</h1>
                            <span className='faq-text'>of RealPeople</span>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <div className='steps'>
                                <div className="step other">
                                    <p className='step-text'>Step 1: Application</p>
                                </div>
                                <div className="step">
                                    <p className='step-text'>Step 2: First Interview</p>
                                </div>
                                <div className="step">
                                    <p className='step-text'>Step 3: Case Study</p>
                                </div>
                                <div className="step">
                                    <p className='step-text'>Step 4: Second Interview</p>
                                </div>
                                <div className="step">
                                    <p className='step-text'>Step 5: Offer & Onboarding</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                            <h1 className='faq-header'>Got Questions?</h1>
                            <p className='faq-text-2'>Here are some of the most common questions<br/> we get about joining the #RealTeam</p>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-12">
                            <div className="accordion" id="accordionExample">
                                {accordionData.map((item, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header">
                                            <button 
                                                className={`accordion-button ${index !== 0 ? 'collapsed' : ''}`} 
                                                type="button" 
                                                data-bs-toggle="collapse" 
                                                data-bs-target={`#collapse${index}`} 
                                                aria-expanded={index === 0 ? 'true' : 'false'} 
                                                aria-controls={`collapse${index}`}
                                            >
                                                {item.title}
                                            </button>
                                        </h2>
                                        <div 
                                            id={`collapse${index}`} 
                                            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} 
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {item.content}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
