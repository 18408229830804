import React, { useEffect, useState } from 'react';
import '../styles/Form.css';
import downArrowIcon from '../images/DownArrow.svg';
import arrowUp from '../images/Arrowup.png';
import closeBtn from '../images/CloseBtn.svg';



export default function Form() {

    const companyDepartments = {
        'RealMinds': ['Strategy', 'Production', 'Graphic Design', 'Motion Design', 'Project Management', 'Social Media & Content Creation', 'Account Management', 'Copywriting'],
    };

    const [formData, setFormData] = useState({
        fullName: '',
        phone: '',
        department: 'Strategy',
        resume: null
    });
    const [countryCode, setCountryCode] = useState('+90');
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('Upload CV (*PDF only)');
    const [selectedDepartment, setSelectedDepartment] = useState('Strategy');
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertType, setAlertType] = useState('');

    const handleCountrySelect = (code) => {
        setCountryCode(code);
        setDropdownOpen(null);
    };

    const toggleDropdown = (dropdown) => {
        setDropdownOpen(prevDropdown => (prevDropdown === dropdown ? null : dropdown));
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({ ...formData, resume: file });
            setSelectedFileName(file.name);
        }
    };

    const handleDepartmentSelect = (department) => {
        setSelectedDepartment(department);
        setDropdownOpen(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('fullName', formData.fullName);
        formDataToSend.append('phone', `${countryCode} ${formData.phone}`);
        formDataToSend.append('company', 'RealPeople');
        formDataToSend.append('department', selectedDepartment);
        formDataToSend.append('resume', formData.resume);

        try {
            const response = await fetch('https://career.realworksglobal.com/backend/formHandler.php', {
                method: 'POST',
                body: formDataToSend,
            });

            if (response.ok) {
                setAlertMessage('Başvurunuz başarıyla gönderildi!');
                setAlertType('success');
            } else {
                const errorData = await response.json();
                setAlertMessage(`Hata: ${errorData.error}`);
                setAlertType('danger');
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            setAlertMessage('Bir hata oluştu, lütfen tekrar deneyin.');
            setAlertType('danger');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.form-section-phone-button')) {
                setDropdownOpen(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => {
                setAlertMessage(null);
            }, 3000); // 3 saniye sonra bildirimi gizle
            return () => clearTimeout(timer);
        }
    }, [alertMessage]);


    return (
        <>
            <div className='form-section' id='form'>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p className='form-send-text'>Send us your <span>application</span> </p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12 col-12 d-flex justify-content-center">
                                <div className="form-border">
                                    <form className="application-form" id='form-inner' onSubmit={handleSubmit}>
                                        <div className="form-section-name">
                                            <input
                                                type="text"
                                                name="fullName"
                                                placeholder="Name Surname"
                                                className="form-section-input"
                                                value={formData.fullName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-section-phone">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="Phone Number"
                                                className="form-section-input form-section-input-special"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                            <div className="form-section-phone-button form-section-phone-special">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <button type="button" className="form-section-downArrow" onClick={() => toggleDropdown('country')}>
                                                        <img src={downArrowIcon} alt="Down Arrow" />
                                                    </button>
                                                    <button type="button" className="input-button">{countryCode}</button>
                                                </div>
                                                {dropdownOpen === 'country' && (
                                                    <div className="form-dropdown-menu">
                                                        {/* Avrupa Ülkeleri */}
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+44')}>UK (+44)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+33')}>France (+33)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+49')}>Germany (+49)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+39')}>Italy (+39)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+34')}>Spain (+34)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+31')}>Netherlands (+31)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+41')}>Switzerland (+41)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+43')}>Austria (+43)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+30')}>Greece (+30)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+46')}>Sweden (+46)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+47')}>Norway (+47)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+45')}>Denmark (+45)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+48')}>Poland (+48)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+40')}>Romania (+40)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+32')}>Belgium (+32)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+351')}>Portugal (+351)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+352')}>Luxembourg (+352)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+353')}>Ireland (+353)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+358')}>Finland (+358)</div>

                                                        {/* Amerika Ülkeleri */}
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+1')}>USA (+1)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+52')}>Mexico (+52)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+55')}>Brazil (+55)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+54')}>Argentina (+54)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+57')}>Colombia (+57)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+56')}>Chile (+56)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+58')}>Venezuela (+58)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+1')}>Canada (+1)</div>

                                                        {/* Asya Ülkeleri */}
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+81')}>Japan (+81)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+82')}>South Korea (+82)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+86')}>China (+86)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+91')}>India (+91)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+62')}>Indonesia (+62)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+65')}>Singapore (+65)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+60')}>Malaysia (+60)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+66')}>Thailand (+66)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+971')}>United Arab Emirates (+971)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+90')}>Turkey (+90)</div>

                                                        {/* Afrika Ülkeleri */}
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+27')}>South Africa (+27)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+234')}>Nigeria (+234)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+212')}>Morocco (+212)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+20')}>Egypt (+20)</div>
                                                        <div className="form-dropdown-item" onClick={() => handleCountrySelect('+254')}>Kenya (+254)</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-section-phone">
                                            <input type="text" placeholder="Department you’re interested in" className="form-section-input" readOnly />
                                            <div className="form-section-phone-button strategy">
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <button type="button" className='form-section-downArrow' onClick={() => toggleDropdown('department')}>
                                                        <img src={downArrowIcon} alt="Down Arrow" />
                                                    </button>
                                                    <p className='form-section-input-text'>{selectedDepartment}</p>
                                                </div>
                                                {dropdownOpen === 'department' && (
                                                    <div className="form-dropdown-menu">
                                                        {companyDepartments['RealMinds'].map((department) => (
                                                            <div className="form-dropdown-item" key={department} onClick={() => handleDepartmentSelect(department)}>
                                                                {department}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-section-phone upload-file">
                                            <div className="form-section-input">
                                                <div className='upload-section-ph'>
                                                    {selectedFileName}
                                                </div>
                                                <input
                                                    type="file"
                                                    accept=".pdf"
                                                    id="fileInput"
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileUpload}
                                                    required
                                                />
                                                <div
                                                    className="form-section-folder-button"
                                                    onClick={() => document.getElementById('fileInput').click()}
                                                >
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div>
                                                            <img src={arrowUp} alt="Arrow Up Icon" />
                                                        </div>
                                                        <div>
                                                            <p>Select Folder</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checkbox-container">
                                            <input type="checkbox" id="gdpr-checkbox" className="form-section-checkbox" required />
                                            <label htmlFor="gdpr-checkbox" className="checkbox-text" onClick={toggleModal}>
                                                <span>Click here</span> to see how we keep your data safe and GDPR-compliant.
                                            </label>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button className="form-section-button" type="submit">Complete Application</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='notif'>
                            {alertMessage && (
                                <div className={`alert alert-${alertType} alert-show`} role="alert">
                                    {alertMessage}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {modalOpen && (
                    <div className="form-modal-overlay" onClick={toggleModal}>
                        <div className="form-modal-content" onClick={(e) => e.stopPropagation()}>
                            <button className="form-modal-close" onClick={toggleModal}>
                                <img src={closeBtn} alt="" />
                            </button>
                            <p className='form-modal-content-text'>*As a data subject, you can always withdraw your explicit consent. <br /> <br />
                                I understand that the resumes submitted through the website and mail system mean transfer abroad since the mail system and website servers are located abroad. <br />
                                I have been informed about the ways to physically submit my application. <br /> <br />
                                I give my explicit consent for my data (identity, contact, professional experience, other)
                                contained in my resume and mail/website application form to be stored abroad for the main process of
                                Purchasing Goods and Services, information technologies and service procurement requiring expertise. <br /> <br />
                                In the main process of conducting the recruitment processes of employee candidates, I agree that all my data
                                (identity, communication, professional experience, other) detailed in the clarification text regarding the process
                                and created during the job application process will be stored for 2 (two) years from the notification of the negative
                                evaluation of my application for the purpose of evaluating it in suitable positions to be opened later.
                                I have been informed that I can withdraw my explicit consent at any time.</p>
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}
