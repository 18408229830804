import React from 'react';
import '../styles/Match.css'; // Stil dosyanız
import remo from '../images/REMO.png'
import { useNavigate } from 'react-router-dom';

const Match = () => {
  const navigate = useNavigate(); // useNavigate hook'unu kullanın


  const handleClick = () => {
    navigate('/faq');
  }

  return (
    <div className="recruitment-page" id='match-menu'>
      <div className="match-inner">
        <div className="text-content-match">
          <div className="text-content-match-inner">
            <h1 className='text-title-match'>Are we a <br /> <span className='text-span-match'>real match?</span></h1> <br />
            <p className='text-subtext-match'>Let’s find out together!</p> <br />
            <p className='text-p-match'>Our recruitment process is a two-way street, where you get to know us, and we get to know you.</p>
            <button onClick={handleClick} className="process-button">Our Process</button>
          </div>
        </div>
        <div className="image-content-match">
          <div className="image-content-match-inner">
            <img src={remo} alt="Character" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Match;
